
import { OrderedListOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import axios from 'axios';
import { Vue, Options } from 'vue-class-component';
import infoComponentVue from '../components/info.component.vue';

@Options({
    components: {
        'info': infoComponentVue,
        'extension-table-outlined': OrderedListOutlined,
        'upload-outlined': UploadOutlined
    }
})
export default class ImportTasks extends Vue {
    loading = true;
    csvFile: any = null;
    csvFileLabel: any = '';
    loadingUpload = false;
    columns = [
        {
            title: 'Created at',
            dataIndex: 'created_at'
        },
        {
            title: 'Import Type',
            dataIndex: 'import_type',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Rows Imported',
            dataIndex: 'rows_imported',
        },
        {
            title: 'Total Rows',
            dataIndex: 'total_rows',
        },
        {
            title: 'Error Message',
            dataIndex: 'error_message',
        },
    ];
    rows = [];
    loadingTable = true;
    pagination = {
        current: 1,
        pageSize: 100,
        total: 1
    };

    async getImportTasks() {
        try {
            this.loadingTable = true;
            let url = `${process.env.VUE_APP_API_URL!}/import-tasks`;
            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            if (data.error) {
                message.error(data.error_message);
            } else {
                data.import_tasks.forEach((row: any) => {
                    row.key = row.id;
                    try {
                        row.error_message = row.error_message.split('On Line No:')[0];
                    } catch (error) {
                        //
                    }
                });
                this.pagination = {
                    current: 1,
                    pageSize: 100,
                    total: 100
                };
                this.loadingTable = false;
                this.rows = data.import_tasks;
            }
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    mounted() {
        console.log('[APP] Import Tasks Mounted');
        this.getImportTasks();
    }
}
